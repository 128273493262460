import { FetchApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  GetAllSchemaRequest,
  SchemaApi,
  CreateSchemaRequest,
} from './SchemaApi';
import IResponseData from './types/Response';

export class SchemaRestApi implements SchemaApi {
  private readonly fetchApi: FetchApi;
  private readonly baseUrl: string;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    fetchApi: FetchApi;
    baseUrl: string;
    identityApi: IdentityApi;
  }) {
    this.fetchApi = options.fetchApi;
    this.baseUrl = options.baseUrl;
    this.identityApi = options.identityApi;
  }

  async getAllSchema(req: GetAllSchemaRequest = {}): Promise<IResponseData> {
    const { token } = await this.identityApi.getCredentials();
    const resp = await this.fetchApi.fetch(
      `${this.baseUrl}/api/proxy/aliran/schemas`,
      { method: 'GET', headers: { Authorization: `Bearer ${token}` } },
    );
    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }

    return (await resp.json()) as IResponseData;
  }

  async createSchema(req: CreateSchemaRequest): Promise<any> {
    const { token } = await this.identityApi.getCredentials();
    const resp = await this.fetchApi.fetch(
      `${this.baseUrl}/api/proxy/aliran/schema/upload`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(req),
      },
    );
    if (!resp.ok) {
      throw await resp.json();
    }
  }
}
