import { createApiRef } from '@backstage/core-plugin-api';
import IResponseData from './types/Response';

/*
 *@public
 * */
export const schemaApiRef = createApiRef<SchemaApi>({
  id: 'plugin.schema.service',
});

export interface GetAllSchemaRequest {}

export interface CreateSchemaRequest {
  name: string;
  nameSpace: string;
  schema: string;
  version: string;
}

/*
 *@public
 * */
export interface SchemaApi {
  getAllSchema(req: GetAllSchemaRequest): Promise<IResponseData>;
  createSchema(req: CreateSchemaRequest): Promise<any>;
}
