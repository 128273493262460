import { useContext } from 'react';
import { SchemaDrawer } from '../SchemaDrawer';
import { SchemaContext } from '../SchemaIndexPage';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  NativeSelect,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ReactJson from 'react-json-view-ts';

export const SchemaCreationForm = () => {
  const { update, state } = useContext(SchemaContext);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    update({ ...state, [name]: value });
  };

  return (
    <SchemaDrawer open={state.openForm}>
      <form>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
        >
          <Grid item>
            <TextField
              required
              id="name-input"
              name="schemaName"
              label="Schema name"
              type="text"
              value={state.schemaName}
              onChange={handleInputChange}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="namespace-input"
              name="namespace"
              label="Namespace"
              type="text"
              value={state.namespace}
              onChange={handleInputChange}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="version-input"
              name="version"
              label="Version"
              type="text"
              value={state.version}
              onChange={handleInputChange}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item style={{ marginTop: '5%' }}>
            <FormControl fullWidth>
              <InputLabel variant="standard">Format</InputLabel>
              <NativeSelect value={state.format}>
                <option value={'JSON'}>JSON</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          {state.value ? (
            <ReactJson
              src={JSON.parse(state.value)}
              onEdit={e => {
                state.value = JSON.stringify(e.updated_src);
                update(state);
              }}
              onAdd={e => {
                state.value = JSON.stringify(e.updated_src);
                update(state);
              }}
              onDelete={e => {
                state.value = JSON.stringify(e.updated_src);
                update(state);
              }}
            />
          ) : (
            <TextField
              required
              id="value-input"
              name="value"
              label="Schema"
              type="text"
              value={state.value}
              onChange={handleInputChange}
              margin="normal"
              variant="outlined"
              multiline
              minRows={30}
              style={{ width: '100%' }}
            />
          )}
        </Grid>
      </form>
    </SchemaDrawer>
  );
};
