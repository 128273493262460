import {
  PageWithHeader,
  Content,
  ContentHeader,
} from '@backstage/core-components';
import { SchemaTable } from '../SchemaList';
import { SchemaCreationButton } from '../SchemaCreationButton';
import { createCtx } from '../../hooks/createContext';
import { SchemaCreationForm } from '../SchemaCreationForm';

export default interface SchemaContextProps {
  openForm: boolean;
  schemaName: string;
  namespace: string;
  format: string;
  version: string;
  value: string;
}

const defaultSchemaContextProps: SchemaContextProps = {
  openForm: false,
  schemaName: '',
  namespace: '',
  format: 'JSON',
  version: '',
  value: '',
};

const [ctx, Provider] = createCtx<SchemaContextProps>(
  defaultSchemaContextProps,
);

export const SchemaContext = ctx;

export const SchemaIndexPage = () => {
  return (
    <PageWithHeader title="Schema Management" themeId="service">
      <Provider>
        <Content>
          <ContentHeader title="">
            <SchemaCreationButton />
            <SchemaCreationForm />
          </ContentHeader>
          <SchemaTable />
        </Content>
      </Provider>
    </PageWithHeader>
  );
};
