import { PropsWithChildren, useContext, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { SchemaContext } from '../SchemaIndexPage';
import Grid from '@material-ui/core/Grid';
import { useApi } from '@backstage/core-plugin-api';
import { schemaApiRef, CreateSchemaRequest } from '../../api';

const useDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '50%',
      justifyContent: 'inherit',
      padding: theme.spacing(5.5),
    },
  }),
);

const useDrawerContentStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    icon: {
      fontSize: 20,
    },
    content: {
      height: '80%',
      backgroundColor: '#EEEEEE',
    },
    button: {
      marginTop: theme.spacing(2.5),
    },
    secondaryAction: {
      marginLeft: theme.spacing(2.5),
    },
  }),
);

const DrawerContent = ({ children }: PropsWithChildren<{}>) => {
  const classes = useDrawerContentStyles();
  const { update, state } = useContext(SchemaContext);
  const schemaApi = useApi(schemaApiRef);
  const [success, setSuccess] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const [errMsg, setErrMsg] = useState<string>();

  const handleClose = () => {
    setSuccess(false);
    setError(false);
  };
  let action: string;
  state.value ? (action = 'Update') : (action = 'Create');

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h5">Create Schema</Typography>
        <IconButton
          key="dismiss"
          title="Close the drawer"
          onClick={() => {
            state.openForm = false;
            let newState = { ...state };
            update(newState);
          }}
          color="inherit"
        >
          <Close className={classes.icon} />
        </IconButton>
      </div>
      {children}
      <div>
        <Grid container spacing={2} className={classes.button}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                let payload: CreateSchemaRequest = {
                  name: state.schemaName,
                  version: state.version,
                  schema: state.value,
                  nameSpace: state.namespace,
                };
                schemaApi
                  .createSchema(payload)
                  .then(() => {
                    setSuccess(true);
                    location.reload();
                  })
                  .catch(resp => {
                    setError(true);
                    setErrMsg(resp.Message);
                  });
              }}
            >
              {action}
            </Button>
            <Snackbar
              open={success}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={handleClose}
            >
              <Alert severity="success" onClose={handleClose}>
                {action} successful
              </Alert>
            </Snackbar>
            <Snackbar
              open={error}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onClose={handleClose}
            >
              <Alert severity="error" onClose={handleClose}>
                {errMsg}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export const SchemaDrawer = ({
  children,
  open,
}: PropsWithChildren<{ open: boolean }>) => {
  const classes = useDrawerStyles();
  const { update, state } = useContext(SchemaContext);
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        state.openForm = false;
        let newState = { ...state };
        update(newState);
      }}
      classes={{ paper: classes.paper }}
    >
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
};
