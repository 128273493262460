import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  createRouteRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { schemaApiRef, SchemaRestApi } from './api';

export const rootRouteRef = createRouteRef({
  id: 'schema',
});

/** @public */
export const schemaPlugin = createPlugin({
  id: 'schema',
  apis: [
    createApiFactory({
      api: schemaApiRef,
      deps: {
        configApi: configApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ configApi, fetchApi, identityApi }) =>
        new SchemaRestApi({
          fetchApi: fetchApi,
          baseUrl: configApi.getString('backend.baseUrl'),
          identityApi: identityApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

/** @public */
export const SchemaIndexPage = schemaPlugin.provide(
  createRoutableExtension({
    name: 'SchemaIndexPage',
    component: () => import('./Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
