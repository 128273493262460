import { Button } from '@material-ui/core';
import { useContext } from 'react';
import { SchemaContext } from '../SchemaIndexPage';
import SchemaContextProps from '../SchemaIndexPage/SchemaIndexPage';

export const SchemaCreationButton = () => {
  const { update } = useContext(SchemaContext);
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          const defaultSchemaContextProps: SchemaContextProps = {
            openForm: true,
            schemaName: '',
            namespace: '',
            format: 'JSON',
            version: '',
            value: '',
          };

          update(defaultSchemaContextProps);
        }}
      >
        Create Schema{' '}
      </Button>
    </>
  );
};
