import { useEffect, useState, useContext } from 'react';
import { Table, TableColumn } from '@backstage/core-components';
//import { makeStyles } from '@material-ui/core/styles';
import IResponseData from '../../api/types/Response';
import { ISchema } from '../../api/types/Schema';
import { useApi } from '@backstage/core-plugin-api';
import { schemaApiRef, GetAllSchemaRequest } from '../../api';
import { SchemaContext } from '../SchemaIndexPage';

//const useStyles = makeStyles(theme => ({
//  container: {
//    width: 850,
//  },
//  empty: {
//    padding: theme.spacing(2),
//    display: 'flex',
//    justifyContent: 'center',
//  },
//}));

const columns: TableColumn[] = [
  {
    title: 'Schema Name',
    field: 'name',
  },
  {
    title: 'Version',
    field: 'version',
  },
  {
    title: 'Format',
    field: 'format',
  },
  {
    title: 'Namespace',
    field: 'namespace',
  },
];

export const SchemaTable = () => {
  const [schemas, setSchemas] = useState<Array<ISchema>>([]);
  const schemaApi = useApi(schemaApiRef);
  const { update, state } = useContext(SchemaContext);

  useEffect(() => {
    let req: GetAllSchemaRequest = {};
    schemaApi.getAllSchema(req).then(response => setSchemas(response.items));
  }, []);

  return (
    <Table
      columns={columns}
      title="Schema list"
      data={schemas}
      onRowClick={(_, rowData: any) => {
        state.value = rowData.value;
        state.format = rowData.format;
        state.version = rowData.version;
        state.namespace = rowData.namespace;
        state.schemaName = rowData.name;
        state.openForm = true;
        let newState = { ...state };
        update(newState);
      }}
    />
  );
};
