/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2079.95 456.05"
    >
      <g
        transform="translate(200.000000,755.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          className={classes.path}
          d="M4493 7385 c-133 -29 -253 -125 -313 -250 -30 -61 -35 -82 -38 -167
-5 -123 18 -209 82 -295 164 -223 483 -250 685 -58 66 63 104 126 126 210 54
210 -47 430 -240 522 -112 53 -189 62 -302 38z"
        />
        <path
          className={classes.path}
          d="M13441 7389 c-127 -25 -267 -132 -321 -244 -112 -234 -17 -501 217
-617 58 -28 78 -33 164 -36 125 -5 210 18 296 81 103 76 161 171 183 299 53
304 -233 577 -539 517z"
        />
        <path
          className={classes.path}
          d="M1805 6649 c-264 -33 -512 -162 -726 -377 -264 -267 -417 -600 -465
-1017 -74 -636 161 -1165 610 -1376 156 -73 213 -83 441 -83 182 1 209 3 297
27 176 47 298 113 421 229 37 35 67 60 67 57 0 -3 -11 -56 -25 -117 -14 -61
-25 -116 -25 -122 0 -7 136 -9 452 -8 l453 3 16 110 c22 143 118 770 175 1145
24 162 64 426 89 585 24 160 63 412 85 560 22 149 43 280 45 293 l5 22 -454 0
c-425 0 -455 -1 -459 -17 -3 -10 -13 -54 -22 -98 -26 -126 -23 -122 -57 -69
-89 134 -300 226 -579 253 -131 13 -242 12 -344 0z m358 -874 c286 -60 481
-349 424 -629 -53 -257 -248 -429 -502 -443 -268 -15 -495 151 -561 410 -21
82 -14 229 15 310 47 133 151 249 277 309 115 54 228 68 347 43z"
        />
        <path
          className={classes.path}
          d="M8385 6649 c-515 -66 -973 -505 -1130 -1083 -204 -753 23 -1444 557
-1691 120 -55 215 -76 373 -82 329 -13 564 64 770 250 41 38 75 65 75 62 0 -3
-11 -56 -25 -117 -14 -61 -25 -115 -25 -119 0 -5 204 -9 453 -9 l453 0 42 283
c24 155 72 473 108 707 92 601 189 1241 224 1475 17 110 33 212 36 228 l6 27
-455 0 -455 0 -16 -67 c-8 -38 -18 -85 -21 -106 -10 -51 -19 -53 -45 -12 -55
89 -204 177 -371 219 -161 41 -391 55 -554 35z m360 -874 c206 -44 370 -206
420 -416 78 -331 -184 -659 -527 -659 -308 0 -548 240 -548 547 0 195 121 393
293 478 124 61 236 76 362 50z"
        />
        <path
          className={classes.path}
          d="M11440 6653 c-240 -18 -470 -89 -633 -196 -153 -100 -238 -195 -308
-342 -62 -129 -74 -188 -74 -365 0 -132 3 -165 22 -225 59 -186 196 -331 420
-444 100 -50 181 -82 378 -151 197 -69 281 -110 330 -160 32 -33 40 -48 40
-80 0 -106 -128 -175 -325 -174 -175 1 -378 55 -597 159 -105 50 -286 163
-369 230 l-31 26 -7 -48 c-4 -27 -31 -208 -61 -403 l-54 -355 22 -17 c107 -84
366 -200 547 -247 224 -57 294 -65 555 -65 257 -1 333 8 512 60 111 31 270
108 350 169 102 76 188 177 238 279 65 132 86 224 86 376 0 405 -224 649 -776
845 -310 109 -377 144 -407 205 -22 46 -14 84 27 125 49 48 112 67 247 72 236
11 488 -54 771 -196 93 -47 97 -48 102 -28 2 12 13 83 24 157 12 74 35 227 51
339 17 112 29 210 26 218 -3 7 -45 32 -93 54 -300 139 -679 207 -1013 182z"
        />
        <path
          className={classes.path}
          d="M15245 6654 c-121 -18 -173 -29 -265 -60 -602 -201 -1007 -877 -977
-1633 3 -80 13 -184 21 -231 73 -398 275 -690 581 -841 137 -67 230 -90 392
-96 337 -14 592 71 789 262 35 35 64 61 64 58 0 -2 -11 -54 -25 -115 -14 -61
-25 -117 -25 -124 0 -12 74 -14 452 -12 l452 3 103 675 c56 371 114 749 127
840 14 91 44 284 66 430 22 146 56 371 76 500 19 129 37 243 40 253 5 16 -21
17 -450 17 l-455 0 -10 -37 c-5 -21 -17 -73 -26 -116 l-17 -78 -30 47 c-77
115 -248 203 -469 239 -93 15 -356 27 -414 19z m378 -895 c293 -99 445 -422
337 -712 -114 -303 -476 -436 -771 -281 -84 44 -175 137 -218 222 -86 170 -82
367 12 531 69 123 230 237 367 260 77 14 201 4 273 -20z"
        />
        <path
          className={classes.path}
          d="M6571 6570 c-257 -93 -380 -383 -265 -627 59 -124 198 -228 338 -254
193 -35 403 75 487 254 42 90 54 172 40 261 -29 175 -140 309 -306 366 -79 27
-218 27 -294 0z"
        />
        <path
          className={classes.path}
          d="M5410 6575 c0 -7 -49 -329 -156 -1030 -41 -269 -99 -654 -130 -855
-30 -201 -71 -465 -90 -588 -19 -123 -34 -227 -34 -233 0 -5 178 -9 453 -9
l453 0 146 962 146 961 -24 36 c-51 79 -77 168 -82 291 -6 168 30 287 129 418
l40 52 -426 0 c-234 0 -425 -2 -425 -5z"
        />
        <path
          className={classes.path}
          d="M13030 6543 c0 -3 -38 -254 -135 -888 -160 -1052 -188 -1235 -226
-1485 -22 -146 -42 -275 -44 -287 l-5 -23 454 0 455 0 6 33 c3 17 30 194 60
392 30 198 82 540 115 760 34 220 76 501 95 625 19 124 53 344 75 489 22 146
40 269 40 274 0 4 -13 -1 -29 -13 -45 -32 -149 -78 -216 -95 -75 -19 -211 -19
-294 0 -94 22 -213 89 -287 160 -35 33 -64 59 -64 58z"
        />
        <path
          className={classes.path}
          d="M4077 6341 c-16 -102 -57 -377 -93 -611 -78 -511 -111 -731 -204
-1340 -39 -256 -73 -480 -76 -497 l-6 -33 456 0 c277 0 456 4 456 9 0 9 81
544 205 1356 24 160 74 484 110 720 36 237 68 448 71 470 l6 39 -23 -17 c-56
-39 -128 -77 -189 -98 -88 -30 -248 -37 -344 -15 -91 21 -207 83 -281 150
l-60 53 -28 -186z"
        />
      </g>
    </svg>
  );
};

export default LogoFull;
