//import 'antd/dist/antd.css';
import { ReactChild, ReactChildren, useEffect, useState } from 'react';
import '@ant-design/compatible';
import { ApolloProvider } from '@apollo/react-hooks';
import cubejs, { CubejsApi } from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import client from '../../graphql/client';
import {
  PageWithHeader,
  Content,
  ContentHeader,
  TabbedLayout,
} from '@backstage/core-components';
import WebSocketTransport from '@cubejs-client/ws-transport';
import { cubeDashboardRef } from '../../api/CubeDashboardApi';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import moment from 'moment';

interface AppProps {
  children: ReactChildren | ReactChild;
}

export const CubeDashboardIndexPage = ({ children }: AppProps) => {
  // Get cubejs Url
  const config = useApi(configApiRef);
  const cubeApiUrl = config.getString('cubeDashboard.cubeApiUrl');

  const cubeDashboardApi = useApi(cubeDashboardRef);
  const [cube, setCube] = useState<CubejsApi | null>();
  useEffect(() => {
    let token: string | null = localStorage.getItem('cubeToken');
    let expires: string | null = localStorage.getItem('cubeToken.expires');

    // Check if token is stored in local storage, and also check if the token is expired.
    if (
      token === null ||
      (expires !== null &&
        moment.unix(parseInt(expires)).isBefore(moment.now()))
    ) {
      cubeDashboardApi.generateCubeToken().then(respToken => {
        // Default use http
        let cubeJsApi = cubejs(respToken, {
          apiUrl: cubeApiUrl,
        });

        // If found websocket scheme in the url, then use websocket
        if (cubeApiUrl.includes('ws://')) {
          cubeJsApi = cubejs({
            transport: new WebSocketTransport({
              authorization: respToken,
              apiUrl: cubeApiUrl,
            }),
            apiUrl: cubeApiUrl,
          });
        }
        setCube(cubeJsApi);
        localStorage.setItem('cubeToken', respToken);
        localStorage.setItem(
          'cubeToken.expires',
          moment().add(1, 'hours').unix().toString(),
        );
      });
    } else {
      // Default use http
      let cubeJsApi = cubejs(token, {
        apiUrl: cubeApiUrl,
      });

      if (cubeApiUrl.includes('ws://')) {
        cubeJsApi = cubejs({
          transport: new WebSocketTransport({
            authorization: token,
            apiUrl: cubeApiUrl,
          }),
          apiUrl: cubeApiUrl,
        });
      }
      setCube(cubeJsApi);
    }
  }, []);

  return (
    <div>
      {cube ? (
        <CubeProvider cubejsApi={cube as CubejsApi | null}>
          <ApolloProvider client={client}>
            <PageWithHeader title="Dashboard" themeId="service">
              <Content>
                <TabbedLayout>
                  <TabbedLayout.Route title="Dashboard" path="/">
                    <ContentHeader title="Saved dashboard"></ContentHeader>
                  </TabbedLayout.Route>
                  <TabbedLayout.Route title="Explore" path="/explore">
                    <ContentHeader title="Explore datasets"></ContentHeader>
                  </TabbedLayout.Route>
                </TabbedLayout>
                {children}
              </Content>
            </PageWithHeader>
          </ApolloProvider>
        </CubeProvider>
      ) : (
        <div></div>
      )}
    </div>
  );
};
