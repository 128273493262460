import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CubeDashboardIndexPage } from './components/CubeDashboardIndexPage';
import ExplorePage from './pages/ExplorePage';
import DashboardPage from './pages/DashboardPage';

/*Public*/
export const Router = () => (
  <CubeDashboardIndexPage>
    <Routes>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/explore" element={<ExplorePage />} />
      <Route path="/explore?itemId=:id" element={<ExplorePage />} />
    </Routes>
  </CubeDashboardIndexPage>
);
