import { createApiRef } from '@backstage/core-plugin-api';

/*
 *@public
 * */
export const cubeDashboardRef = createApiRef<cubeDashboardApi>({
  id: 'plugin.cube-dashboard.service',
});

/*
 *@public
 * */
export interface cubeDashboardApi {
  generateCubeToken(): Promise<string>;
}
