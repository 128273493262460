import { createPermission } from '@backstage/plugin-permission-common';

// Permission for academy's page visibilites
export const academyVisiblePermission = createPermission({
  name: 'academy.plugin.visible',
  attributes: { action: 'read' },
});

export const academyPermission = [academyVisiblePermission];

// Permission for academy's page visibilites
export const dataCoeVisiblePermission = createPermission({
  name: 'datacoe.plugin.visible',
  attributes: { action: 'read' },
});

export const dataCoePermission = [dataCoeVisiblePermission];
