import { FetchApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { cubeDashboardApi } from './CubeDashboardApi';
import { IResponseData } from '@internal/plugin-token-issuer-backend/src/api';

export class CubeDashboardApi implements cubeDashboardApi {
  private readonly fetchApi: FetchApi;
  private readonly baseUrl: string;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    fetchApi: FetchApi;
    baseUrl: string;
    identityApi: IdentityApi;
  }) {
    this.fetchApi = options.fetchApi;
    this.baseUrl = options.baseUrl;
    this.identityApi = options.identityApi;
  }

  async generateCubeToken(): Promise<string> {
    // Get oauth access token
    const accessToken: string | null = getCookie('gAccessToken');
    if (!accessToken) {
      throw new Error('Missing google oauth access token!');
    }

    const resp = await this.fetchApi.fetch(
      `${this.baseUrl}/api/token-issuer/cubejs`,
      {
        method: 'GET',
        headers: {
          Authorization: accessToken,
        },
      },
    );
    if (!resp.ok) {
      throw await ResponseError.fromResponse(resp);
    }
    const respBody: IResponseData = await resp.json();
    return respBody.token;
  }
}

function getCookie(name: string) {
  const cookieName = encodeURIComponent(name) + '=';
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();

    if (cookie.startsWith(cookieName)) {
      return decodeURIComponent(cookie.substring(cookieName.length));
    }
  }

  return null;
}
